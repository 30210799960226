import { SEO } from "gatsby-theme-core";
import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@streamo/helper/methods";
import { Layout, HeaderOne, FooterOne } from "gatsby-theme-layout";
import {
    PageHeader01,
    SupportSolutionArea,
    FeatureSection01,
} from "gatsby-theme-container";

const SupportSolutionPage = ({ data }) => {
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO pageName="Support Solution Page" />
            <HeaderOne data={{ ...data.header, ...data.navigation }} />
            <PageHeader01 data={content?.["page-header-section"]} />
            <SupportSolutionArea data={content?.["support-solution-section"]} />
            <FeatureSection01 data={content?.["features-section"]} />
            <FooterOne data={{ ...data.footer, ...data?.contact }} />
        </Layout>
    );
};

export const query = graphql`
    query SupportSolutionPageQuery {
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-streaming" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact01
        }
        page(title: { eq: "support-solution" }) {
            content {
                ...PageContent
            }
        }
    }
`;
SupportSolutionPage.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({}),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default SupportSolutionPage;
